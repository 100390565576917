import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import PageNotFound from '../404/404.page';
import { PageContext } from '../../types/types';
import { css } from '../../helpers/css.theme';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { postContentCss } from '../blog/single/components/postContent.css';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';
import { Container } from '../../layouts/main.layout';
import { COUNTRY } from '../../configs/env';

interface WpPageNodeI {
  node: {
    id: string;
    slug: string;
    date: string;
    title: string;
    content: string;
  };
}

interface TermsPageDataPropsI {
  data: {
    allWordpressPage: {
      edges: Array<WpPageNodeI>;
    };
  };
  pageContext: PageContext;
}

type PersonalRightsPageType = (props: TermsPageDataPropsI) => ReactElement;

const PersonalRightsPage: PersonalRightsPageType = (props) => {
  const { t } = useTranslation();
  const lang = props.pageContext.lang;
  const meta = {
    title: t('meta_PersonalRightsTitle'),
    description: t('meta_PersonalRightsDescription'),
  };

  let pageContent = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `gdpr-operations-bv_en_29-07-2022`);

  if (lang === 'sr' || lang === 'me') {
    pageContent = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `personal-data-protection-rules-sr`);
  }

  if (lang === 'bg') {
    pageContent = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `personal-data-protection-rules-bg`);
  }

  if (pageContent) {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('personalRight_MainHeading')}
        subText={t('personalRight_MainSubText')}
        backgroundImage="bg/packages.jpg"
        backgroundImageMobile="bg/packages-mobile@3x.jpg"
        storeBadgeIcons
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: pageContent.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  }

  return <PageNotFound />;
};

export const allPostsQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          slug
          date
          content
          id
        }
      }
    }
  }
`;

export default PersonalRightsPage;
